@font-face {
   font-family: 'EffraBold';
   src: url('../fonts/Effra_Std_Bd.ttf');
   font-weight: bold;
}

@font-face {
   font-family: 'EffraRegular';
   src: url('../fonts/Effra_Std_Rg.ttf');
   // font-weight: 100;
}

$font-bold: 'EffraBold';
$font-regular: 'EffraRegular';
