@import '../../assets/styles.scss';

$border-width: 8px; // width of the red left border and horizontal paddings

.sidebar {
   position: fixed;
   width: $sidebar-width;
   background-color: $color-light-purple;
   height: calc(100vh - 60px);
   padding: 60px 0 0;
   overflow-y: auto;

   display: flex;
   flex-direction: column;
   justify-content: space-between;

   .bottomLink a {
      font-size: 20rem * $font-size-modifier;
      color: $color-purple !important;
   }

   &--bottomLinks {
      margin: 28px 28px 60px;
   }

   &--upperSection {
      margin: 0 36px 36px;
      position: relative;
      overflow: visible;
   }

   &--backButton {
      padding: 0;
      width: 30px;
      text-align: left;
      border: none;
      outline: none;
      background-color: transparent;

      &:hover {
         cursor: pointer;
         opacity: 0.6;
      }

      img {
         width: 12px;
      }
   }

   &--elements { // "main menu", the highest level elements

      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 18px;

      .listItem--circle { // list-item.scss
         &:hover {
            opacity: 1 !important;
         }
      }
   }

   &--button {
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 96px;
      border: none;
      border-left: $border-width solid transparent;
      padding: 0 20px;
      background-color: rgba(0, 0, 0, 0);
      text-decoration: none;
      width: 100%;
      overflow: hidden;

      &__active, &:hover {
         cursor: pointer;
         background-color: $color-white;
         border-left: $border-width solid $color-red;

         .link {
            opacity: 1;
         }
      }
   }

   &--additionalLink { // "Seuraa tuloksia ja mittareita >>"
      display: flex;
      align-items: center;
      text-decoration: none;
      padding-left: 28px;
      padding-right: 28px;
      height: 64px;

      &:hover {
         cursor: pointer;
         opacity: 0.6;
      }
   }

   @media only screen and (max-width: $breakpoint-tablet) {
      padding-top: 28px;
      background-color: $color-white;
      position: static;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
      width: 100%;
      text-align: center;

      &--elements {
         width: 268px;
      }

      &--titleObjectives {
         display: none;
      }

      &--backButton {
         display: flex;
         width: 100%;
         height: 28px;
         margin-bottom: 0;

         img {
            width: 16px;
         }
      }

      &--additionalLink {
         padding-left: 0;
      }

      &--upperSection {
         width: calc(100% - 40px);
         margin: 0;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         margin-bottom: 0;
      }
   }
}

.tooltip {

   p {
      @include link;
      font-family: $font-regular;
      margin-top: 6px;
      font-size: 18rem * $font-size-modifier;
      margin: 0;
      padding: 6px 8px;
      z-index: 999;
      background-color: $color-white;
      border-radius: 8px;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.267);
      white-space: nowrap;
   }

   margin-top: 6px;
   border-radius: 8px;
   background-color: $color-white;
   position: fixed;
   display: flex;
   z-index: 99999;
   pointer-events: none;

   @media only screen and (max-width: $breakpoint-tablet) {
      display: none;
   }
}
