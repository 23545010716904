@import '../../assets/styles.scss';

.listItem {
   display: flex;
   flex-direction: row;
   align-items: center;
   margin: 6px 0;

   &--circle {
      color: $color-white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-bold;
      margin-right: 12px;
      flex-shrink: 0;

      &__small {
         width: 40px;
         height: 40px;
         aspect-ratio: 1;
         font-size: 14px;
      }

      &__medium {
         width: 42px;
         height: 42px;
         aspect-ratio: 1;
         font-size: 16px;
      }

      &__large {
         width: 64px;
         height: 64px;
         aspect-ratio: 1;
         font-size: 20px;
      }
   }

   &--image {
      width: 90%;
      max-width: 90%;
      aspect-ratio: 1;
   }
}
