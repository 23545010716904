@import '../../assets/styles.scss';

.mobileElements {
   background-color: $color-light-purple;
   padding: 16px 0;
}

.kuopioLogo {
   max-width: 200px;
   margin-top: 30px;
   margin-left: 4px;
}

.frontPageLink {
   @include link;

   &:hover {
      opacity: 0.6;
   }
}

.pageStart {
   display: flex;

   &--description {
      margin: 40px 0;

      @media only screen and (max-width: $breakpoint-tablet) {
         margin: 20px 0 48px;
      }
   }

   &--content {

      width: 100%;

      &--inner {
         display: flex;
         flex-direction: column;
         padding: 40px;
         padding-right: 350px;
      }

      min-height: calc(100vh - 40px);
      display: flex;
      flex-direction: column;
      padding: 20px;
      margin-left: $sidebar-width;
      background-image: url('../../assets/images/etusivu-piirroskuva.svg');
      background-repeat: no-repeat;
      background-position: right;
      background-origin: content-box;

      @media only screen and (max-width: $breakpoint-laptop) {
         width: auto;
         background-image: none;
         &--inner {
            padding-right: 0;
         }
      }

      @media only screen and (max-width: $breakpoint-tablet) {
         min-height: auto;
         margin-left: 0;
         padding: 40px 40px 0;
         background-image: none;
      }
   }
}

.pageStart--description {

   margin: 40px 0;
   padding-right: 120px;
   max-width: 1400px;

   @media only screen and (max-width: $breakpoint-laptop) {
      padding-right: 60px;
   }

}
