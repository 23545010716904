@import '../../assets/styles.scss';

// Table

table {
   border-collapse: collapse;
   border: none;
   width: 100%;
}

th {
   padding: 12px 18px;
   text-align: left;
}

td {
   border: 1px solid $color-purple;
   padding: 18px;

   p {
      display: inline;
   }

   a, a:active, a:visited { // links in tasks
      color: $color-purple;
      text-decoration: underline;
   }
}

.td {
   &--evaluation {
      width: 36%;
      background-color: $color-light-purple;
   }

   &--number {
      font-family: $font-bold;
      color: $color-purple;
      display: inline;
   }

   &--headerItem {
      border: none !important;
   }
}

.desktopTable {

   width: 100%;

   &--headers {
      // display: grid;
      display: flex;
      flex-direction: row;
      width: 100%;
      // grid-template-columns: 64% 36%;
      margin-bottom: 8px;

      &--header {
         white-space: nowrap;
         // width: 100%;
         margin-right: -18px;
         padding-left: 18px;
      }
   }
}

.mobileTable {
   margin-bottom: 20px;
   background-color: $color-light-purple;
   padding: 32px 20px;

   &--title {
      margin-bottom: 20px;
   }

   &--taskAndEvaluation {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 24px;

      &--content {
         display: flex;
         flex-direction: row;
         margin-bottom: 6px;
      }

      a, a:visited, a:active {
         color: $color-purple;
      }

      &:nth-child(2) {
         color: $color-purple;
      }
   }
}
