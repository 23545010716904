@import './styles/colors.scss';
@import './styles/fonts.scss';

$breakpoint-laptop: 1200px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 480px;

// Typography

$font-size-modifier: 0.0452;

h1 {
   color: $color-purple;
   font-size: 98px * 0.7; // Old size
   font-family: $font-bold;
   text-transform: uppercase;
   line-height: 62px;

   @media only screen and (max-width: $breakpoint-laptop) {
      font-size: 80rem * $font-size-modifier;
      line-height: 50px;
   }

   @media only screen and (max-width: $breakpoint-mobile) {
      font-size: 60rem * $font-size-modifier;
   }
}

h2 {
   color: $color-purple;
   font-size: 34rem * $font-size-modifier;
   font-family: $font-bold;
   margin: 0;
   padding: 0;
   line-height: 24px;
}

h3 {
   color: $color-purple;
   font-size: 22rem * $font-size-modifier;
   font-family: $font-bold;
   text-transform: uppercase;
   margin: 0;
   padding: 0;
}

h4 {
   color: $color-purple;
   font-size: 24rem * $font-size-modifier;
   font-family: $font-regular;
   font-weight: normal;
   margin: 0;
   padding: 0;

   @media only screen and (max-width: $breakpoint-tablet) {
      font-size: 26rem * $font-size-modifier;
   }

}

p {
   color: $color-black;
   font-size: 20rem * $font-size-modifier;
   font-family: $font-regular;
   margin: 0;
   padding: 0;

   @media only screen and (max-width: $breakpoint-tablet) {
      font-size: 22rem * $font-size-modifier;
   }
}

@mixin link {
   color: $color-red;
   font-size: 20rem * $font-size-modifier;
   font-family: $font-bold;
   text-decoration: none;
   margin: 0;
   padding: 0;

   @media only screen and (max-width: $breakpoint-tablet) {
      font-size: 22rem * $font-size-modifier;
   }

}

p.link, a.link {
   @include link;
}

// Page

$sidebar-width: 340px;

.page {

   display: flex;
   flex-direction: row;
   overflow-x: hidden;

   &--content {
      padding: 60px;
      padding-left: $sidebar-width + 60px;
      width: 100%;
   }

   @media only screen and (max-width: $breakpoint-tablet) {

      flex-direction: column;

      &--content {
         padding: 20px;
      }

   }

}

img {
   user-select: none;
   -webkit-user-drag: none;
}
